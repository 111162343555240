import React, { useEffect, useRef, useState } from "react";
import {
  inscriptionPariticipantCeremonie,
  recupererDetailCeremonieByID
} from "../../../services/ceremonie/CeremonieRequests";
import iconeAppareilPhoto from "../../../assets/IconeSvg/appareil_photo.svg";
import { useParams } from "react-router-dom";
import { Snackbar } from "@mui/material";
import { Oval, ThreeDots } from "react-loader-spinner";
import { formatAmountPrice } from "../../../utils/MoneyFormatter";
import {
  recupererCatechumeneParMatricule,
  recupererInfosCatechumeneParMatriculeEtCeremonie
} from "../../../services/catechumene/CatechumeneRequest";
import { listeCompleteDesModePaiements } from "../../../services/parametrage/ModePaiementRequest";
import { listeCompleteDesMotifPaiementsFree } from "../../../services/parametrage/MotifPaiementRequest";
import { recupererTarificationParMotifPaiementFree } from "../../../services/parametrage/TarificationRequest";

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const InscriptionCeremonie = () => {
  let { ceremonieID } = useParams();
  const fileInputRefPaiement = useRef(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [inscriptionTerminee, setInscriptionTerminee] = useState(false);
  const [loadingInscription, setLoadingInscription] = useState(false);
  const [loadingDetailCeremonie, setLoadingDetailCeremonie] = useState(true);
  const [caremonieDetail, setCaremonieDetail] = useState(null);
  const [inscriptionField, setInscriptionField] = useState({
    catechumene: "",
    matricule: "",
    numeroPaiement: "",
    modePaiement: "",
    referencePaiement: "",
    recuPaiement: null,
    ceremonie: null,
    montant: null
  });
  const [listeNumeroPaiement, setListeNumeroPaiement] = useState([]);
  const [listeMoyenPaiement, setListeMoyenPaiement] = useState([]);
  const [listeMotifPaiement, setListeMotifPaiement] = useState([]);
  const [tarifCeremonie, setTarifCeremonie] = useState(null);
  const [selectedCeremonie, setSelectedCeremonie] = useState(null);
  const [previewPaiment, setPreviewPaiment] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [snackbarSuccessOpen, setSnackbarOpenSuccess] = useState(false);
  const [snackbarErrorOpen, setSnackbarOpenError] = useState(false);
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "center"
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbarSuccess = () => {
    setSnackbarOpenSuccess(false);
  };
  const handleCloseSnackbarError = () => {
    setSnackbarOpenError(false);
  };

  const handleClickPaiement = () => {
    fileInputRefPaiement.current.click();
  };

  const handleFileChangePaiement = (event) => {
    const file = event.target.files[0];
    if (file) {
      setInscriptionField((prev) => ({
        ...prev,
        recuPaiement: file
      }));
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreviewPaiment(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const rechercherInformationCatechumene = () => {
    setLoadingSearch(true);
    recupererCatechumeneParMatricule(inscriptionField.matricule)
      .then((res) => {
        setLoadingSearch(false);
        setInscriptionField((prev) => ({
          ...prev,
          catechumene: res.data,
          numeroPaiement: "",
          modePaiement: "",
          referencePaiement: "",
          recuPaiement: null
        }));
        setPreviewPaiment(null);
      })
      .catch((err) => {
        setLoadingSearch(false);
        setInscriptionField((prev) => ({
          ...prev,
          catechumene: "",
          numeroPaiement: "",
          modePaiement: "",
          referencePaiement: "",
          recuPaiement: null
        }));
        setPreviewPaiment(null);
        if (err && err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
          setSnackbarOpenError(true);
        } else {
          setErrorMessage("Erreur d'enregistrement, rééssayer!");
          setSnackbarOpenError(true);
        }
        console.log("ERREUR", err);
      });
  };

  const validatePhoneNumber = (number) => {
    const regex = /^\+225\d{10}$/;
    return regex.test(number);
  };

  // const validateField = (fields) => {
  //   for (let field of fields) {
  //     if (!inscriptionField[field]) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };
  const validateField = (fields) => {
    for (let field of fields) {
      if (inscriptionField[field] === undefined || inscriptionField[field] === null) {
        return false;
      }
    }
    return true;
  };
  

  const nouvelleInscription = () => {
    setInscriptionTerminee(false);
    setInscriptionField({
      catechumene: "",
      matricule: "",
      numeroPaiement: "",
      modePaiement: "",
      referencePaiement: "",
      recuPaiement: null,
      ceremonie: null,
      montant: null
    });
    setPreviewPaiment(null);
  };

  const validerInscriptionCeremonie = () => {
    if (
      validateField([
        "catechumene",
        "modePaiement",
        "numeroPaiement",
        // "referencePaiement",
        "recuPaiement",
        "ceremonie",
        "montant"
      ])
    ) 
    {
      setLoadingInscription(true);
      const values = {
        ...inscriptionField,
        catechumene: inscriptionField.catechumene.id,
        motifPaiement: inscriptionField.ceremonie,
        anneeScolaire: currentAnnee ? currentAnnee._id : null
      };
      const inscriptionValues = new FormData();
      Object.keys(values).forEach((key) => {
        inscriptionValues.append(key, values[key]);
      });

      inscriptionPariticipantCeremonie(inscriptionValues)
        .then((res) => {
          setInscriptionTerminee(res.data);
          setLoadingInscription(false);
        })
        .catch((err) => {
          setLoadingInscription(false);
          if (err && err.response && err.response.data) {
            setErrorMessage(err.response.data.message);
            setSnackbarOpenError(true);
          } else {
            setErrorMessage("Erreur d'enregistrement, rééssayer!");
            setSnackbarOpenError(true);
          }
          console.log("ERROR=====", err);
        });
    } else {
      setErrorMessage("Veillez renseigner correctement les informations.");
      setSnackbarOpenError(true);
    }
  };

  const getListeModeDePaiement = () => {
    listeCompleteDesModePaiements()
      .then((res) => {
        setListeMoyenPaiement(res.data);
        let liste = [];
        res.data.forEach((element) => {
          if (element.numeroPaiement && element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              secondNumero: element.secondNumeroPaiement,
              syntaxe: element.description
            });
          } else if (element.numeroPaiement && !element.secondNumeroPaiement) {
            liste.push({
              moyen: element.designation,
              numero: element.numeroPaiement,
              syntaxe: element.description,
              secondNumero: ""
            });
          }
        });
        setListeNumeroPaiement(liste);
      })
      .catch((err) => {
        console.log("ERREUR", err);
      });
  };

  // LISTE MOTIF PAIEMENT
  const getListeDesMotifsDePaiement = () => {
    listeCompleteDesMotifPaiementsFree()
      .then((res) => {
        let liste = [];
        res.data.map((item) => {
          if (item.isPayByCustomer) {
            liste.push(item);
          }
        });
        setListeMotifPaiement(liste);
      })
      .catch((error) => {
        console.log("Impossible de récupérer les motifs", error);
      });
  };

  // LISTE TARIFS DES MOTIF
  const getListeTarifDesMotifsDePaiement = (motifId) => {
    recupererTarificationParMotifPaiementFree(motifId)
      .then((res) => {
        setTarifCeremonie(res.data[0]);
        setInscriptionField((prev) => ({
          ...prev,
          ceremonie: motifId,
          montant: res.data[0] ? res.data[0].montant : 0
        }));
        console.log("=====>", {
          ceremonie: motifId,
          montant: res.data[0] ? res.data[0].montant : 0
        });
      })
      .catch((error) => {
        console.log("Impossible de récupérer les tarifs", error);
      });
  };

  useEffect(() => {
    getListeModeDePaiement();
    getListeDesMotifsDePaiement();
    // recupererDetailCeremonieByID(ceremonieID)
    //   .then((res) => {
    //     setCaremonieDetail(res.data);
    //     setLoadingDetailCeremonie(false);
    //   })
    //   .catch((err) => {
    //     setLoadingDetailCeremonie(false);
    //     if (err && err.response && err.response.data) {
    //       setErrorMessage(err.response.data.message);
    //       setSnackbarOpenError(true);
    //     } else {
    //       setErrorMessage("Erreur d'enregistrement, rééssayer!");
    //       setSnackbarOpenError(true);
    //     }
    //   });
  }, []);

  return (
    <div className="pb-16 min-h-screen bg-white">
      {/* <div className="w-full h-screen flex flex-col items-center justify-center bg">
        <Oval
          visible={true}
          height="40"
          width="40"
          strokeWidth="4"
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
        <p className="font-semibold text-gray-400 mt-3">
          Chargement des informations...
        </p>
      </div> */}

      <div className="pb-6">
        <div className="w-full h-fit py-6 ml-5 bg-zinc-50 drop-shadow-sm border-b">
          <div className="w-11/12 max-w-2xl mx-auto">
            <h2 className="text-primary text-3xl text-left font-black">
              Inscription aux cérémonies
            </h2>
            <div className="space-y-2 mt-6">
              <p className="font-semibold text-black">
                Syntaxe et numéro pour le paiement:{" "}
                <div className="mt-5 flex flex-wrap items-center gap-4">
                  {listeNumeroPaiement.length > 0
                    ? listeNumeroPaiement.map((item, index) => (
                        <div className="flex flex-row flex-wrap items-center gap-1 ">
                          <span className="text-gray-500 ">
                            [{item.moyen}] :
                          </span>
                          <span
                            key={index}
                            className="w-fit px-3 py-2 text-sm font-medium rounded-md border bg-white text-gray-500 flex items-center justify-center"
                          >
                            {item.syntaxe}
                          </span>
                        </div>
                      ))
                    : "-"}
                </div>
              </p>
            </div>
          </div>
        </div>

        {!inscriptionTerminee && (
          <div className="pt-7 px-4 max-w-2xl mx-auto">
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text font-medium">
                  Choisir la cérémonie
                </span>
              </div>
              <select
                value={inscriptionField.ceremonie}
                onChange={(e) => {
                  getListeTarifDesMotifsDePaiement(e.target.value);
                }}
                className="custom-select h-10 font-semibold select select-bordered"
              >
                <option disabled selected>
                  Choisir un élément de la liste
                </option>
                {listeMotifPaiement.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.description}
                  </option>
                ))}
              </select>
            </label>
            {inscriptionField.ceremonie && inscriptionField.montant > 0 && (
              <div className="mt-2 w-full h-12 bg-neutral-100 flex items-center px-3 rounded-lg border">
                <p className="font-semibold text-gray-700">
                  Montant des frais de participation:{" "}
                  <span className="font-extrabold text-black">
                    {inscriptionField.montant > 0
                      ? formatAmountPrice(inscriptionField.montant)
                      : null}
                  </span>
                </p>
              </div>
            )}
            {inscriptionField.ceremonie && !inscriptionField.montant && (
              <div className="mt-2 w-full h-12 bg-neutral-100 flex items-center px-3 rounded-lg border">
                <p className="font-semibold text-gray-700">
                  Aucun tarif enregistré pour cette cérémonie
                </p>
              </div>
            )}
            <div className="mt-2 w-full flex items-end gap-3">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text font-medium">
                    Rechercher les infos du catéchumène
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="Entrez votre matricule"
                  className="input input-bordered font-semibold text-sm w-full h-10"
                  value={inscriptionField.matricule}
                  onChange={(e) =>
                    setInscriptionField((prev) => ({
                      ...prev,
                      matricule: e.target.value
                    }))
                  }
                />
              </label>
              <button
                disabled={loadingSearch}
                className="w-36 h-10 rounded-md bg-primary text-white text-sm font-semibold flex items-center justify-center"
                onClick={rechercherInformationCatechumene}
              >
                {!loadingSearch ? (
                  "Rechercher"
                ) : (
                  <ThreeDots
                    height="40"
                    width="40"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                )}
              </button>
            </div>

            {!loadingSearch &&
              inscriptionField.matricule &&
              inscriptionField.catechumene && (
                <div className="mt-6">
                  <p className="text-sm text-gray-500 font-bold">
                    Catéchumène trouvé
                  </p>
                  <div className="mt-2 w-full h-fit bg-zinc-50 rounded-lg shadow-sm border flex items-start gap-3 p-2">
                    <div className="w-32 h-32 bg-gray-200 rounded-lg">
                      <img
                        src={inscriptionField.catechumene.photo}
                        className="w-full h-full object-cover rounded-lg drop-shadow"
                      />
                    </div>
                    <div>
                      <div className="flex flex-wrap items-start gap-x-1 mt-1">
                        <p className="text-sm font-semibold underline">
                          Nom & Prénoms:
                        </p>
                        <p className="text-sm print:text-xs text-black font-bold">
                          {inscriptionField.catechumene.nom}{" "}
                          {inscriptionField.catechumene.prenoms}
                        </p>
                      </div>
                      <div className="flex flex-wrap items-start gap-x-1 mt-1">
                        <p className="text-sm font-semibold underline">
                          Commission:
                        </p>
                        <p className="text-sm print:text-xs text-black font-bold">
                          {inscriptionField.catechumene.commission
                            ? inscriptionField.catechumene.commission
                                .designation
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-wrap items-start gap-x-1 mt-1">
                        <p className="text-sm font-semibold underline">
                          Niveau:
                        </p>
                        <p className="text-sm print:text-xs text-black font-bold">
                          {inscriptionField.catechumene.niveau
                            ? inscriptionField.catechumene.niveau.designation
                            : "-"}
                        </p>
                      </div>
                      <div className="flex flex-wrap items-start gap-x-1 mt-1">
                        <p className="text-sm font-semibold underline">
                          Classe:
                        </p>
                        <p className="text-sm print:text-xs text-black font-bold">
                          {inscriptionField.catechumene.classe
                            ? inscriptionField.catechumene.classe.designation
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-medium">
                        Mode de paiement
                      </span>
                    </div>
                    <select
                      value={inscriptionField.modePaiement}
                      onChange={(e) =>
                        setInscriptionField((prev) => ({
                          ...prev,
                          modePaiement: e.target.value
                        }))
                      }
                      className="select select-bordered font-semibold"
                    >
                      <option value="" disabled selected>
                        Choisir un mode de paiement des frais
                      </option>
                      {listeMoyenPaiement.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.designation}
                        </option>
                      ))}
                    </select>
                  </label>

                  {inscriptionField.modePaiement &&
                    listeMoyenPaiement.find(
                      (itemPaiement, index) =>
                        itemPaiement._id === inscriptionField.modePaiement
                    )?.designation !== "CASH" && (
                      <label className="form-control w-full">
                        <div className="label">
                          <span className="label-text font-medium">
                            Numéro utilisé pour le paiement
                          </span>
                        </div>
                        <input
                          type="tel"
                          placeholder="07080000000"
                          value={inscriptionField.numeroPaiement}
                          onChange={(e) =>
                            setInscriptionField((prev) => ({
                              ...prev,
                              numeroPaiement: e.target.value
                            }))
                          }
                          className="input input-bordered font-semibold text-sm w-full"
                        />
                        {inscriptionField.numeroPaiement &&
                          !validatePhoneNumber(
                            inscriptionField.numeroPaiement
                          ) && (
                            <span className="pt-1 pl-1 text-xs text-primary font-medium italic">
                              Entrer un numéro de téléphone valide commençant
                              par +225
                            </span>
                          )}
                      </label>
                    )}

                  {/* <label className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-medium">
                        Référence du paiement
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="MP09.135.D354"
                      value={inscriptionField.referencePaiement}
                      onChange={(e) =>
                        setInscriptionField((prev) => ({
                          ...prev,
                          referencePaiement: e.target.value
                        }))
                      }
                      className="input input-bordered font-semibold text-sm w-full"
                    />
                  </label> */}

                  <div className="form-control w-full">
                    <div className="label">
                      <span className="label-text font-medium">
                        Reçu du paiement
                      </span>
                    </div>

                    <div
                      onClick={handleClickPaiement}
                      className="flex justify-center items-center w-full h-full rounded-lg cursor-pointer"
                    >
                      {inscriptionField.recuPaiement !== null ? (
                        <div className="relative w-full h-64">
                          <div className="absolute bg-black/20 w-full h-full rounded-lg flex  items-center justify-center">
                            <img
                              src={iconeAppareilPhoto}
                              className="w-10 h-10"
                              alt=""
                            />
                          </div>
                          <img
                            src={previewPaiment}
                            alt="Preview"
                            className="w-full h-full object-contain rounded-lg "
                          />
                        </div>
                      ) : inscriptionField.recuPaiement === null ? (
                        <div className="relative w-full h-64 rounded-full flex justify-center ">
                          <div className="absolute bg-gray-100 top-0 w-full h-full rounded-lg flex justify-center items-center">
                            <img
                              src={iconeAppareilPhoto}
                              className="w-10 h-10"
                              alt=""
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <input
                      type="file"
                      ref={fileInputRefPaiement}
                      style={{ display: "none" }}
                      onChange={handleFileChangePaiement}
                    />
                  </div>

                  <div className="mt-6">
                    <button
                      disabled={loadingInscription}
                      className="w-full h-11 rounded-lg drop-shadow flex items-center justify-center bg-primary text-sm text-white font-bold"
                      onClick={validerInscriptionCeremonie}
                    >
                      {!loadingInscription ? (
                        "Valider la participation"
                      ) : (
                        <ThreeDots
                          height="40"
                          width="40"
                          radius="9"
                          color="#fff"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      )}
                    </button>
                  </div>
                </div>
              )}

            {!loadingSearch && !inscriptionField.catechumene && (
              <div className="mt-6 w-full h-44 bg-zinc-100 rounded-lg shadow-sm border flex items-center justify-center">
                <p className="text-sm text-gray-700 font-semibold">
                  Recherche un catéchumène à inscrire
                </p>
              </div>
            )}

            {loadingSearch &&
              inscriptionField.matricule &&
              !inscriptionField.catechumene && (
                <div className="mt-6 w-full h-44 bg-zinc-100 rounded-lg shadow-sm border flex items-center justify-center">
                  <p className="text-sm text-gray-700 font-semibold">
                    Recherche un catéchumène à inscrire
                  </p>
                </div>
              )}

            {loadingSearch &&
              inscriptionField.matricule &&
              inscriptionField.catechumene && (
                <div className="mt-6 w-full h-44 bg-zinc-100 rounded-lg shadow-sm border flex items-center justify-center">
                  <p className="text-sm text-gray-700 font-semibold">
                    Recherche un catéchumène à inscrire
                  </p>
                </div>
              )}
          </div>
        )}

        {inscriptionTerminee && (
          <div className="w-11/12 max-w-lg mx-auto h-fit pt-20 flex flex-col items-center justify-center">
            <p className="text-xl text-emerald-600 font-bold">
              Inscription réussi avec succès
            </p>
            <p className="text-center text-black font-medium mt-3">
              L'inscription du catéchumène pour la participation à cet événement
              a été faite avec succès. Vous pouvez effectuer d'autres
              inscription pour d'autres catéchumènes
            </p>
            <button
              className="mt-5 w-fit px-6 h-10 rounded-md bg-primary text-white text-sm font-semibold flex items-center justify-center"
              onClick={nouvelleInscription}
            >
              Faire une nouvelle inscription
            </button>
          </div>
        )}
      </div>

      {/* SNACKBAR SUCCESS */}
      {successMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarSuccessOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbarSuccess}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-green-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {successMessage}
          </div>
        </Snackbar>
      ) : null}

      {/* SNACKBAR ERROR */}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={snackbarErrorOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbarError}
          key={vertical + horizontal}
          className="z-[999999]"
        >
          <div className="bg-red-600 text-white font-semibold z-[999999] px-10 py-3 rounded-lg">
            {errorMessage}
          </div>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default InscriptionCeremonie;
