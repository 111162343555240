import React, { useEffect, useState } from "react";
import BaseLayout from "../../layout/BaseLayout";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import IconeBilletArgent from "../../assets/IconeSvg/money-bag.svg";
import IconeGirlie from "../../assets/IconeSvg/hand-money.svg";
import iconeCatechumene from "../../assets/IconeSvg/catechumene.svg";
import iconeAnimateur from "../../assets/IconeSvg/animateur.svg";
import messageIcon from "../../assets/IconeSvg/message-icon.svg";
import iconeClasse from "../../assets/IconeSvg/Classe.svg";

import {
  getStatistiqueAdmission,
  getStatistiqueEnNombre,
  getStatistiqueTransaction,
} from "../../services/dashboard/RequestDashboard";
import { Skeleton } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import { recupereRevenuEtDepenseGenere } from "../../services/paiement/PaiementRequest";
import { formatAmountPrice } from "../../utils/MoneyFormatter";
import {
  returnPermissionsParMenu,
  verificationPermissionsExistantes,
} from "../../utils/ManageUserProfil";

// const listePermissions = returnPermissionsParMenu("/dashboard");

const currentAnnee = localStorage.getItem("currentAnnee")
  ? JSON.parse(localStorage.getItem("currentAnnee"))
  : null;

const Dashboard = () => {
  const listePermissions = returnPermissionsParMenu("/dashboard");
  registerLocale("fr", fr);
  const [listeDonneeStatsBasic, setListeDonneeStatsBasic] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataBasic, setLoadingDataBasic] = useState(false);
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [loadingAdmission, setLoadingAdmission] = useState(false);
  const [statistiqueFinancier, setStatistiqueFinancier] = useState({
    revenue: 0,
    depense: 0,
  });
  const [statistiqueTransaction, setStatistiqueTransaction] = useState([]);
  const [statistiqueAdmissions, setStatistiqueAdmissions] = useState([]);
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [typeTransaction, setTypeTransaction] = useState("REVENU");
  const [decisionConseil, setDecisionConseil] = useState("ADMIS");
  const [sectionAffichage, setSectionAffichage] = useState("commission");

  const getStatistiqueGlobal = () => {
    setLoadingDataBasic(true);
    getStatistiqueEnNombre(currentAnnee._id)
      .then((res) => {
        setLoadingDataBasic(false);
        setListeDonneeStatsBasic(res.data);
      })
      .catch((error) => {
        setLoadingDataBasic(false);
      });
  };

  const getStatistiqueTransactionParMotif = () => {
    setLoadingTransaction(true);
    getStatistiqueTransaction(dateDebut, dateFin, typeTransaction)
      .then((res) => {
        setLoadingTransaction(false);
        setStatistiqueTransaction(res.data);
      })
      .catch((error) => {
        setLoadingTransaction(false);
      });
  };

  console.log("setStatistiqueTransaction :", statistiqueTransaction)

  const getStatistiqueDesAdmissions = () => {
    setLoadingAdmission(true);
    getStatistiqueAdmission(decisionConseil, sectionAffichage)
      .then((res) => {
        setLoadingAdmission(false);
        setStatistiqueAdmissions(res.data);
      })
      .catch((error) => {
        setLoadingAdmission(false);
      });
  };

  const getStatistiqueRevenuSortieCaisse = (debut, fin) => {
    recupereRevenuEtDepenseGenere(debut, fin)
      .then((res) => {
        setStatistiqueFinancier({
          revenue: res.data.montantRevenu,
          depense: res.data.montantDepense,
        });
      })
      .catch((error) => {
        console.log("api error", error);
      });
  };

  useEffect(() => {
    setLoadingData(true);
    getStatistiqueRevenuSortieCaisse("", "");
    getStatistiqueGlobal();
    getStatistiqueTransactionParMotif();
    getStatistiqueDesAdmissions();
  }, []);

  return (
    <BaseLayout>
      {verificationPermissionsExistantes(listePermissions, "VOIR_DETAIL") ? (
        <div className="w-full h-full pt-5 pb-16">
          <div className="w-full">
            <h1 className="text-2xl md:text-3xl font-extrabold">
              Tableau de bord
            </h1>
            <p className="mt-2 text-gray-500 font-medium text-sm sm:text-base md:text-lg">
              Statistiques et bilan des données
            </p>
          </div>

          {/* STATISTIQUES */}
          {verificationPermissionsExistantes(
            listePermissions,
            "VOIR_LISTE"
          ) && (
            <div className="mt-10 grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                <div className=" bg-[#a706061c] px-3 py-2.5 rounded-full">
                  <img src={iconeCatechumene} className="h-8 w-8" alt="" />
                </div>
                <div className="">
                  <h3 className="text-gray-400 text-sm font-medium">
                    Nombres catéchumènes
                  </h3>
                  <span className="font-extrabold text-black text-lg">
                    {listeDonneeStatsBasic &&
                      listeDonneeStatsBasic.nombreCatechumenes}
                  </span>
                </div>
              </div>
              <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                <div className=" bg-[#a706061c] px-3 py-2.5 rounded-full">
                  <img src={iconeAnimateur} className="h-8 w-8" alt="" />
                </div>
                <div className="">
                  <h3 className="text-gray-400 text-sm font-medium">
                    Nombre Animateurs
                  </h3>
                  <span className="font-extrabold text-black text-lg">
                    {loadingDataBasic ? (
                      <Skeleton height={20} width={60} />
                    ) : (
                      listeDonneeStatsBasic &&
                      listeDonneeStatsBasic.nombreAnimateurs
                    )}
                  </span>
                </div>
              </div>
              <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                <div className=" bg-[#a706061c] px-3 py-2.5 rounded-full">
                  <img src={iconeClasse} className="h-8 w-8" alt="" />
                </div>
                <div className="">
                  <h3 className="text-gray-400 text-sm font-medium">
                    Nombre Classes
                  </h3>
                  <span className="font-extrabold text-black text-lg">
                    {loadingDataBasic ? (
                      <Skeleton height={20} width={60} />
                    ) : (
                      listeDonneeStatsBasic &&
                      Number(listeDonneeStatsBasic.nombreClasse) - 1
                    )}
                  </span>
                </div>
              </div>
              <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                <div className=" bg-[#a706061c] px-3 py-2.5 rounded-full">
                  <img src={messageIcon} className="h-8 w-8" alt="" />
                </div>
                <div className="">
                  <h3 className="text-gray-400 text-sm font-medium">
                    Nombre SMS envoyé
                  </h3>
                  <span className="font-extrabold text-black text-lg">
                    {loadingDataBasic ? (
                      <Skeleton height={20} width={60} />
                    ) : (
                      listeDonneeStatsBasic &&
                      listeDonneeStatsBasic.nombreSmsEnvoyes
                    )}
                  </span>
                </div>
              </div>
              {verificationPermissionsExistantes(
                listePermissions,
                "VOIR_STAT_FINANCE"
              ) && (
                <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                  <div className="">
                    <img src={IconeBilletArgent} className="h-8 w-8" alt="" />
                  </div>
                  <div className="">
                    <h3 className="text-gray-400 text-sm font-medium">
                      Revenu générée
                    </h3>
                    <span className="font-extrabold text-black text-lg">
                      {formatAmountPrice(statistiqueFinancier.revenue)}
                    </span>
                  </div>
                </div>
              )}
              {verificationPermissionsExistantes(
                listePermissions,
                "VOIR_STAT_FINANCE"
              ) && (
                <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                  <div className="">
                    <img src={IconeGirlie} className="h-10 w-10" alt="" />
                  </div>
                  <div className="">
                    <h3 className="text-gray-400 text-sm font-medium">
                      Sortie de caisse
                    </h3>
                    <span className="font-extrabold text-black text-lg">
                      {formatAmountPrice(statistiqueFinancier.depense)}
                    </span>
                  </div>
                </div>
              )}
              {verificationPermissionsExistantes(
                listePermissions,
                "VOIR_STAT_FINANCE"
              ) && (
                <div className="bg-white w-full h-20 border rounded-xl flex items-center justify-start gap-3 px-3">
                  <div className="">
                    <img src={IconeGirlie} className="h-10 w-10" alt="" />
                  </div>
                  <div className="">
                    <h3 className="text-gray-400 text-sm font-medium">
                      Solde/Reste
                    </h3>
                    <span className="font-extrabold text-black text-lg">
                      {formatAmountPrice(statistiqueFinancier.revenue - statistiqueFinancier.depense)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* STATISTIQUES Revenus */}
          {verificationPermissionsExistantes(
            listePermissions,
            "VOIR_STAT_FINANCE"
          ) && (
            <div className="mt-10 border-t pt-5">
              <h2 className="text-lg font-semibold text-gray-700">
                Statistiques transaction par motif:
              </h2>

              <div className="mt-2 pb-2 flex items-end lg:flex-row md:flex-row flex-col justify-end gap-x-2 overflow-x-scroll sm:overflow-auto">
                <label className="form-control  lg:w-fit md:w-fit w-full">
                  <div className="label -mb-1">
                    <span className="label-text text-xs font-semibold">
                      Type de transaction
                    </span>
                  </div>
                  <select
                    disabled={loadingTransaction}
                    value={typeTransaction}
                    onChange={(e) => setTypeTransaction(e.target.value)}
                    className="custom-select select select-bordered font-medium w-full h-10 "
                  >
                    <option disabled selected>
                      Choisir un type
                    </option>
                    <option value="DEPENSE">Dépense</option>
                    <option value="REVENU">Revenu</option>
                  </select>
                </label>
                <div className="flex flex-row  gap-x-2">
                  <label className="form-control  lg:w-fit md:w-fit w-full">
                    <div className="label -mb-1">
                      <span className="label-text text-xs font-semibold">
                        Date début
                      </span>
                    </div>
                    <DatePicker
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      disabled={loadingTransaction}
                      selected={dateDebut}
                      onChange={(date) => setDateDebut(date)}
                      className="font-medium lg:w-32 md:w-32 w-full h-9 text-sm rounded-lg px-3"
                    />
                  </label>
                  <label className="form-control  lg:w-fit md:w-fit w-full">
                    <div className="label -mb-1">
                      <span className="label-text text-xs font-semibold">
                        Date fin
                      </span>
                    </div>
                    <DatePicker
                      locale="fr"
                      dateFormat="dd/MM/yyyy"
                      disabled={loadingTransaction}
                      selected={dateFin}
                      onChange={(date) => setDateFin(date)}
                      className="font-medium lg:w-32 md:w-32 w-full h-9 text-sm rounded-lg px-3"
                    />
                  </label>
                </div>

                <button
                  disabled={loadingTransaction}
                  className="bg-gray-600 text-sm text-white h-9 w-fit px-4 mt-7 font-semibold rounded-lg flex items-center justify-center"
                  onClick={getStatistiqueTransactionParMotif}
                >
                  {!loadingTransaction ? (
                    "Rechercher"
                  ) : (
                    <ThreeDots
                      height="35"
                      width="35"
                      radius="9"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  )}
                </button>
              </div>

              <div className="mt-3 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 ">
                {statistiqueTransaction.length > 0 &&
                  statistiqueTransaction.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white w-full border rounded-xl flex items-start justify-start p-3"
                    >
                      <div className="gap-y-8">
                        <h3 className="text-primary text-sm font-bold">
                          {item.motif}
                        </h3>
                        <span className="font-extrabold text-black text-lg">
                          {formatAmountPrice(item.montantTotal)}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* STATISTIQUE Depenses */}
          {verificationPermissionsExistantes(
            listePermissions,
            "VOIR_LISTE"
          ) && (
            <div>
              <div className="mt-10 border-t pt-5">
                <h2 className="mt-2 text-lg font-semibold text-gray-700">
                  Statistiques des admissions:
                </h2>

                <div className="mt-2 flex items-end justify-end gap-3">
                  <label className="form-control w-fit">
                    <div className="label -mb-1">
                      <span className="label-text text-xs font-semibold">
                        Décision du conseil
                      </span>
                    </div>
                    <select
                      disabled={loadingAdmission}
                      value={decisionConseil}
                      onChange={(e) => setDecisionConseil(e.target.value)}
                      className="custom-select select select-bordered font-medium w-full h-10 max-w-xs"
                    >
                      <option disabled selected>
                        Choisir une décision
                      </option>
                      <option value="ADMIS">Admis</option>
                      <option value="RECALE">Récalé</option>
                      <option value="RETROGRADE">Rétrogradé</option>
                    </select>
                  </label>

                  <label className="form-control w-fit">
                    <div className="label -mb-1">
                      <span className="label-text text-xs font-semibold">
                        Section de filtre
                      </span>
                    </div>
                    <select
                      disabled={loadingAdmission}
                      value={sectionAffichage}
                      onChange={(e) => setSectionAffichage(e.target.value)}
                      className="custom-select select select-bordered font-medium w-full h-10 max-w-xs"
                    >
                      <option disabled selected>
                        Choisir une section
                      </option>
                      <option value="commission">Commission</option>
                      <option value="classe">Classe</option>
                    </select>
                  </label>

                  <button
                    disabled={loadingAdmission}
                    className="bg-gray-600 text-sm text-white h-9 w-fit px-4 mt-7 font-semibold rounded-lg flex items-center justify-center"
                    onClick={getStatistiqueDesAdmissions}
                  >
                    {!loadingAdmission ? (
                      "Rechercher"
                    ) : (
                      <ThreeDots
                        height="35"
                        width="35"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    )}
                  </button>
                </div>
              </div>
              <div className="mt-3 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 ">
                {statistiqueAdmissions.length > 0 &&
                  statistiqueAdmissions.map((item, index) => (
                    <div
                      key={index}
                      className="bg-white w-full border rounded-xl flex items-start justify-start p-3"
                    >
                      <div className="gap-y-8">
                        <h3 className="text-primary text-sm font-bold">
                          {item.section}
                        </h3>
                        <span className="font-extrabold text-black text-lg">
                          Total: {item.nombre}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="h-56 flex items-center justify-center">
          <p className="text-base text-primary font-extrabold">
            Vous n'avez pas accès à ce menu.
          </p>
        </div>
      )}
    </BaseLayout>
  );
};

export default Dashboard;
